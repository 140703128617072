import React from 'react';
import { IoMdPricetag } from 'react-icons/io';
import { Container, Highlights } from './styles';

const IPVA = () => {
  const getYear = () => {
    const year = new Date().getFullYear();

    const targetDate = new Date(`${year}-12-13T15:00:00`);
    const currentDate = new Date();
    if (currentDate > targetDate) {
      return year + 1;
    }

    return year;
  };

  return (
    <Container bgColor={getYear() === 2024 ? '#bcf738' : '#8DFFFA'}>
      <Container.Body>
        <IoMdPricetag fill="#171c24" size={28} />
        <span className="text">
          Todos os carros com <Highlights>IPVA {getYear()} pago!</Highlights>
        </span>
      </Container.Body>
    </Container>
  );
};

export default IPVA;
